import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import UnlockOrderListUtil ,{IUnlockOrderListDataObj} from './unlockOrderListUtil';

export default defineComponent ({
    name: 'UnlockOrderList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IUnlockOrderListDataObj=reactive<IUnlockOrderListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                cardFrom:'UnlockOrderList',
                isShowFixCol:false,
                modelMethod: utils.OrderProviderApi.buildUrl('/unlockOrder/pageData')
            },
            otherParams:{
                dialogTitle:'',
                type:0,
                dialogVisible:false,
                showPayAllFlag:false,//是否显示全款支付的提示标识
                selInfo:{
                    F_PI_ID:'',//piId
                    F_ORDER_CODE:'',//订单号
                    F_AMOUNT:0,//金额
                },
                customerData:[],//客户下拉数据
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new UnlockOrderListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});